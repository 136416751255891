import { useState, useEffect } from 'react'
import useDebounce from './useDebounce'

export const useViewportWidth = (debounceTime = 50): number => {
  const isSSR = typeof window === 'undefined'
  const [viewportWidth, setViewportWidth] = useState(isSSR ? 992 : window.innerWidth)

  useEffect(() => {
    if (!isSSR) {
      const handleResize = () => setViewportWidth(window.innerWidth)
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [isSSR])

  return debounceTime > 0 ? useDebounce(viewportWidth, debounceTime) : viewportWidth
}
